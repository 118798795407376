footer {
  background-color: #f8f8f8;
  margin-top: 2rem;
  height: 350px;
}

.footerFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoFooter {
  width: 69px;
  height: 58px;
  margin-top: 32px;
}

.redes {
  text-align: center;
  margin-top: 48px;
}

.facebookLogo {
  height: 48px;
  width: 48px;
  color: black;
}

.instagramLogo {
  height: 48px;
  width: 48px;
  margin-left: 32px;
  color: black;
}

.wppLogo {
  height: 48px;
  width: 48px;
  margin-left: 32px;
  color: black;
}

.footer--contactIcons{
  list-style-type: none;
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.contactIcon{
  width: 1rem;
}

.contactIcons--link{
  text-decoration: none;
  color: black;
}

.contactIcons--link:hover {
  color: #967167;
  font-weight: 600;
}

.footerText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 32px;
  color: #28241b;
}


@media screen and (max-width: 577px) {
  .footer--contactIcons{
    flex-direction: column;
    align-items: center;
  }
}