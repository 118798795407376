@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap");

.flexContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 722px;
}

.homeBackground {
  width: 100vw;
  height: 874px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: #f7efef;
  mix-blend-mode: darken;
}

/* En este hay que hacer medias */
.backgroundImg {
  width: 60%;
  height: 100%;
  opacity: 0.42;
  mix-blend-mode: luminosity;
}

.headerImg {
  width: 400px;
  height: 520px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.headerLogo {
  position: absolute;
  width: 5.5rem;
  left: 93rem;
  bottom: 9.5rem;
  display: none;
}

.textPosition {
  margin-left: 153px;
  margin-bottom: 3rem;
  width: 534px;
}

.headerHand {
  width: 4rem;
  margin: 1rem 20%;
}

.headerText {
  font-weight: 400;
  font-size: 50px;
  line-height: 49px;
  color: #28241b;
}

.headerButton {
  font-family: "raleway";
  background-color: unset;
  width: 230px;
  height: 50px;
  border: 1px solid #967167;
  margin-top: 48px;
}

.headerButton:hover {
  color: #967167;
}

.videoBackground {
  background-color: #f1edea;
  mix-blend-mode: darken;
}

.videoSection {
  margin-top: -1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

video {
  width: 1001px;
  height: 668px;
}

.videoText {
  font-family: "raleway";
  width: 612px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #28241b;
}

.videoDotUp {
  font-size: 48px;
  color: #28241b;
  margin-bottom: 60px;
}

.videoDotDown {
  font-size: 48px;
  color: #28241b;
  margin-top: 60px;
}

.descriptionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeLogo {
  width: 79px;
  height: 140px;

  margin-top: 86px;
}

.made {
  width: 206px;
  height: 20px;
  text-align: center;
  margin-top: 48px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  color: #967167;
}

.inspirado {
  width: 404px;
  height: 147px;
  font-family: "Noto Serif Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 54px;
  text-align: center;
  color: #28241b;

  margin-top: 32px;
}

.homeDescription {
  font-family: "raleway";
  width: 578px;
  height: 73px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #28241b;

  margin-top: 16px;
}

.list {
  margin-top: 64px;
}

.productsList {
  font-family: "raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #967167;
  margin-left: 32px;
}

.listDot {
  font-size: 40px;
  color: #967167;
  margin-left: 48px;
}

.imgCollage {
  display: grid;
  width: 100vw;
  margin: 64px auto 0px auto;
  grid-template-columns: auto max-content auto;
  grid-template-rows: repeat(2, max-content);
  column-gap: 1rem;
  justify-items: center;
}

.imgAnillo {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 237px;
  height: 297px;
}

.imgAros {
  grid-column: 2/3;
  grid-row: 1/3;
  width: 383.2px;
  height: 509px;
  margin-left: -6rem;
  z-index: 1;
}

.imgCollar {
  grid-column: 3/4;
  grid-row: 1/2;
  width: 373px;
  height: 404px;
}

.imgColgante {
  grid-column: 1/2;
  grid-row: 2/3;
  width: 500px;
  height: 257px;
}

.imgAnillos {
  grid-column: 3/4;
  grid-row: 2;
  margin-top: 1rem;
  width: 373px;
  height: 490px;
}

@media screen and (min-width: 1420px ) {
  .imgCollage {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 400px 1fr;
    justify-items: center;
    column-gap: 10rem;
    margin-top: 64px;
  }
  
  .imgAnillo {
    grid-column: 1 / 3;
    grid-row: 1;
    width: 237px;
    height: 297px;
  }
  
  .imgAros {
    grid-column: 2;
    grid-row: 1/2;
    width: 383.2px;
    height: 509px;
  }
  
  .imgCollar {
    grid-column: 2/5;
    grid-row: 1;
  
    width: 373px;
    height: 404px;
  }
  
  .imgColgante {
    grid-column: 1/3;
    grid-row: 2;
  
    width: 457px;
    height: 257px;
    z-index: -1;
  }
  
  .imgAnillos {
    grid-column: 2/5;
    grid-row: 2;
    margin-top: 1rem;
    width: 373px;
    height: 490px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1300px) {
  .imgAnillo {
    margin-right: 4rem;
    width: 237px;
    height: 297px;
  }

  .imgAros {
    margin-top: 4rem;
    width: 300px;
    height: 400px;
  }

  .imgCollar {
    width: 300px;
    height: 404px;
  }

  .imgColgante {
    width: 400px;
    height: 257px;
    z-index: -1;
  }

  .imgAnillos {
    width: 373px;
    height: 490px;
  }
}

@media screen and (min-width: 577px) and (max-width: 900px) {
  .homeBackground {
    width: 100%;
    height: 740px;
  }

  .flexContainer {
    height: max-content;
  }

  .headerImg {
    width: 293px;
    height: 344px;
    margin-right: 1rem;
  }

  .textPosition {
    margin: 70px 1% 100px 40px;
    width: 311px;
  }

  .headerText {
    font-size: 36px;
  }
  video {
    width: 800px;
    height: 600px;
  }

  .productsList {
    margin-left: 15px;
  }

  .listDot {
    margin-left: 15px;
  }

  .imgCollage {
    width: 97vw;
    margin: 4rem auto 7rem auto;
  }

  .imgAnillo {
    justify-self: center;
    width: 148.12px;
    height: 191.32px;
  }

  .imgColgante {
    max-width: 290px;
    height: 158.61px;
    justify-self: flex-start;
  }

  .imgAros {
    width: 250px;
    height: 300px;
    margin-top: 1rem;
  }

  .imgCollar {
    justify-self: flex-start;
    width: 200px;
    height: 220px;
  }

  .imgAnillos {
    justify-self: center;
    width: 170px;
    height: 229px;
  }
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  .flexContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .homeBackground {
    width: 100%;
    height: 123%;
  }

  .backgroundImg {
    width: 100%;
    height: 100%;
    opacity: 0.42;
    mix-blend-mode: luminosity;
  }
  .headerImg {
    width: 268px;
    height: 315px;
  }

  .textPosition {
    margin-left: 50px;
    text-align: start;
    width: auto;
  }

  .headerHand {
    margin: 1rem 30%;
  }

  .headerText {
    font-size: 36px;
  }

  .headerButton {
    margin-top: 4px;
  }
  video {
    width: 375px;
    height: 420px;
  }
  .videoText {
    width: 375px;
    height: 383px;
  }

  .videoDotUp {
    margin-bottom: 10px;
  }

  .videoDotDown {
    margin-top: 10px;
  }

  .inspirado {
    width: auto;
  }

  .homeDescription {
    width: 335px;
    height: 75px;
  }

  .list {
    margin-top: 70px;
  }

  .productsList {
    font-size: 12px;
    margin-left: 10px;
  }

  .listDot {
    margin-left: 10px;
  }

  .imgCollage {
    width: 97vw;
    margin: 3rem auto 7rem auto;
  }

  .imgAnillo {
    grid-column: 1/3;
    grid-row: 1;
    width: 150px;
    height: 200px;
    justify-self: flex-start;
  }

  .imgColgante {
    grid-column: 1/4;
    grid-row: 1;
    margin-top: 205px;
    margin-left: -130px;
    width: 213.01px;
    height: 119.79px;
    z-index: -1;
  }

  .imgAros {
    grid-column: 2;
    grid-row: 1;
    width: 189.39px;
    height: 251.7px;
    margin-left: 150px;
  }

  .imgCollar {
    grid-column: 2/5;
    grid-row: 1;
    margin-top: 260px;
    margin-left: 185px;
    width: 123px;
    height: 134.33px;
  }

  .imgAnillos {
    grid-column: 2;
    grid-row: 1;
    margin-top: 340px;
    margin-right: 55px;
    width: 138px;
    height: 180px;
  }
}
