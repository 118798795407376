@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@300&family=Raleway&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  background-color: #f8f8f8;
}

img {
  width: 100%;
  height: auto;
}

p, span, a {
  font-family: 'Raleway', sans-serif;
}

h1 {
  font-family: 'Noto Serif Display', serif;
  font-size: 48px;
  font-weight: 800;
}

button {
  height: 61px;
  background-color: transparent;
  transition: all 200ms ease-in-out;
}

button.btn1 {
  border: 1px solid black;
}

button.btn2 {
  border: 1px solid #967167;
}

button.btn1:hover {
  border-color: #967167;
  color: #967167;
}

button.btn2:hover {
  color: #967167;
}

.contactSection {
  padding: 4rem 0;
  background-color: #f1edea;
  margin-top: 4rem;
}
