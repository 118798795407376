.loadingHand{
    width: 8rem;
    margin: 3rem 46%;
    animation: loadingAnimation 1.8s ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes loadingAnimation {
    0% {
        transform: scale(0.3);
    }

    50% {
        transform: scale(1);   
    }

    100% {
        transform: scale(0.3);
    }
}

@media screen and (max-width: 576px) {
    .loadingHand{
        margin: 3rem 35%;
    }
}

@media screen and (min-width: 577px) and (max-width: 834px) {
    .loadingHand{
        margin: 3rem 43%;
    }
}