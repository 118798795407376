@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap");

.producto-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(396px, 1fr));
  gap: 2rem 1rem;
  padding: 4rem 2rem;
}

.card {
  height: max-content;
}

.card-img-top {
  height: 510px;
}

.card-title {
  font-family: "raleway";
  font-size: 15px;
}

.consulta-link {
  font-family: "raleway";
  text-decoration: none;
  color: black;
  font-weight: 700;
}
.consulta-link:hover{
  color: #967167;
}

@media screen and (max-width: 873px) {
  .producto-container {
    grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  }

  .card-img-top {
    height: 450px;
  }
}
